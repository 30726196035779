import React from "react";
import { Typography } from "antd";
import { CloseCircleFilled, CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";

const ERROR_COLOR = "#0A1339";
const SUCCESS_COLOR = "#FDB415";

const iconStyle = { fontSize: 50, borderRadius: 30 };
const titleStyle = { textAlign: 'center'}

interface ModalContentProps {
  title?: string;
  content: HTMLElement;
  type: "success" | "error" | "confirm";
}

const { Title } = Typography;

const ModalError = () => {
  return (
    <>
      <div id="icon-container">
        <div>
          <CloseCircleFilled
            style={{ ...iconStyle, color: ERROR_COLOR }}
          />
        </div>
      </div>
      <Title level={3} style={{ textAlign: 'center', color: ERROR_COLOR}}>
        Error
      </Title>
    </>
  );
};

const ModalSuccess = () => {
    return (
      <>
        <div id="icon-container">
          <div>
            <CheckCircleFilled
              style={{ ...iconStyle, color: SUCCESS_COLOR }}
            />
          </div>
        </div>
        <Title level={3} style={{ textAlign: 'center', color: SUCCESS_COLOR}}>
          Success
        </Title>
      </>
    );
  };

  const ModalConfirm = () => {
    return (
      <>
        <div id="icon-container">
          <div>
            <ExclamationCircleFilled
              style={{ ...iconStyle, color: SUCCESS_COLOR }}
            />
          </div>
        </div>
        <Title level={3} style={{ textAlign: 'center', color: SUCCESS_COLOR}}>
          Confirm
        </Title>
      </>
    );
  };

const ModalContent = (props: ModalContentProps) => {
  let RenderModalType;
  switch (props.type) {
    case "error":
      RenderModalType = ModalError;
      break;
    case "success":
      RenderModalType = ModalSuccess;
      break;
    case "confirm":
      RenderModalType = ModalConfirm;
      break;
  }
  return (
    <div className="appetit-modal-container">
      {RenderModalType && <RenderModalType />}
      {props.content}
    </div>
  );
};

export default ModalContent;
