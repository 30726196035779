import React, { Component } from "react";
import { Layout } from 'antd';
import HeaderView from "./HeaderView";
import SideMenuView from "./SideMenuView";

const { Sider, Content } = Layout;

interface ILayoutViewProps {
    
}

class LayoutView extends Component<ILayoutViewProps>
{
    state = {
        collapsed: false,
    };

    toggleCollapse = (collapsed: boolean) => {
        this.setState({ collapsed });
    };


    render()
    {
        return (
            <Layout>
                {localStorage.getItem("access_token") ? (
                <span>
                    <HeaderView/>
                    <Layout>
                        <Sider theme="light" trigger={null} collapsible collapsed={this.state.collapsed} onCollapse={this.toggleCollapse}>
                            <SideMenuView siderCollapsed={this.state.collapsed} collapseToggle={this.toggleCollapse} />
                        </Sider>
                        <Content style={{
                                padding: 12,
                                minHeight: 280
                            }}>
                            {this.props.children}
                        </Content>
                    </Layout> 
                </span> 
                ):(
                    <Content style={{
                        padding: 12,
                        minHeight: 280
                    }}>
                        {this.props.children}
                    </Content>
                )}
            </Layout>   
        );
    }
}


export default LayoutView