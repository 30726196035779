import React, { Component } from "react";
import "./App.css";
import axios from 'axios'
import Loader from "./components/Loader";
import LayoutView from "./modules/layout/LayoutView";
import lazyComponentLoader from './hoc/LazyLoader';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './hoc/ProtectedRoute';
import { isArray } from "util";
import DialogUtils from "./utils/DialogUtils";

interface IAppState {
  isLoading:Boolean
  errorVisible:Boolean
}

//include modules
const Login:any = lazyComponentLoader(() => import('./modules/auth/login/Login'));
const Forgot:any = lazyComponentLoader(() => import('./modules/auth/forgot-password/ForgotPassword'));
const Reset:any = lazyComponentLoader(() => import('./modules/auth/forgot-password/ResetPassword'));
const MerchantManagementView:any = lazyComponentLoader(() => import('./modules/merchant-management/MerchantManagementView'));
const PayablesManagementView:any = lazyComponentLoader(() => import('./modules/payable-management/PayableManagementView'));
const DeliveryPayablesManagementView:any = lazyComponentLoader(() => import('./modules/delivery-payable-management/DeliveryPayableManagementView'));
const OrderManagementView:any = lazyComponentLoader(() => import('./modules/orders-management/OrderManagementView'));
const VoucherManagementView:any = lazyComponentLoader(() => import('./modules/voucher-management/VoucherManagementView'));
const UnknownUrlViewView= lazyComponentLoader(() => import('./modules/unknown-url-management/UnknownUrlViewView'));
const SalesHistoryManagementView:any = lazyComponentLoader(() => import('./modules/sales-history-management/SalesHistoryView'));
const LocationManagementView:any = lazyComponentLoader(() => import('./modules/location-management/LocationManagementView'));
const CategoryManagementView:any = lazyComponentLoader(() => import('./modules/category-management/CategoryManagementView'));


class App extends Component<any, IAppState> {

  constructor(props:any) {
    super(props);


    //initialize state here
    this.state = {
        isLoading: false,
        errorVisible: true
    }

    const self = this;
    axios.interceptors.request.use(function (config) {
      // spinning start to show
      self.setState({isLoading: true});
      
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      // spinning hide
      self.setState({isLoading: false});
      
      return response;
    }, async (error) => {
      if (error.response) {
        this.showError(error.response.data.errors);
      } else {
        this.showError(error);
      }
      self.setState({isLoading: false});
      return Promise.reject(error);
    });
  }

  showError(errors:any)
  {
    let content = null;
    if(isArray(errors))
    {
     content = (
      < div>{
            errors.map( (item:any, index:number) => { return <div key={Math.random().toString(6)}>{item.message.includes("timeout") || item.message.includes("access_token") ? "Your session has timed out." : item.message}</div>})
            }
      </div>    
      );
    }else {
     content = ( <div>{errors.message.includes('Network Error') ? 'No network connection. Make sure that WI-FI or Cellular Mobile Data is turned on, then try again.' : errors.message}</div> );
    }
    
    DialogUtils.error(content);
  }
  
  render() {

    return (
      <div>
        <Router>
            <Switch>
                {/* render={props => (<Login {...props} />)} */}
                <Route exact path='/login' component={Login}/>
                <Route exact path='/forgot-password' component={Forgot}/>
                <Route exact path='/reset-password' component={Reset}/>
                <LayoutView>
                  <ProtectedRoute exact path='/' component={MerchantManagementView} />
                  <ProtectedRoute exact path='/:link' component={UnknownUrlViewView} />
                  <ProtectedRoute exact path='/merchants' component={MerchantManagementView} />
                  <ProtectedRoute exact path='/orders' component={OrderManagementView} />
                  <ProtectedRoute exact path='/merchant_payables' component={PayablesManagementView} />
                  <ProtectedRoute exact path='/delivery_payables' component={DeliveryPayablesManagementView} />
                  <ProtectedRoute exact path='/vouchers' component={VoucherManagementView} />
                  <ProtectedRoute exact path='/sales_reports' component={SalesHistoryManagementView} />
                  <ProtectedRoute exact path='/locations' component={LocationManagementView} />
                  <ProtectedRoute exact path='/categories' component={CategoryManagementView} />
               </LayoutView>  
            </Switch>
        </Router>
        
        {this.state.isLoading ? <Loader /> : null}
      </div>
    );  
  }
}

export default App;
