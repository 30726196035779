import React, { Component, useState, useEffect } from 'react';
import { ExportOutlined, SettingOutlined, SearchOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import logo from '../../assets/img/logo.png';


const HeaderView = (props: any) => {
    const [user, setUser] = useState({ first_name: '' });

    useEffect(() => {
        let userString: string | null = localStorage.getItem('user') ? localStorage.getItem('user') : '{}';
        const userObj = JSON.parse(userString ? userString : '');
        setUser(userObj);
    }, []);

    const onLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('order_type');

        props.history.push('/login');

    }

    const handleClick = (path: string) => {
        props.history.push(path)
    }

    return (
        <div className="header">
            <div className="header__left">
                <div className="header__logo">
                    {/* <a onClick={handleClick.bind(null, '/')}> */}
                        <div className="logo_img">
                            <img src={logo} alt="appetit logo" />
                        </div>
                    {/* </a> */}
                </div>
                {/* <div className="header__menu">
                    <Menu mode="horizontal" defaultSelectedKeys={['1']}>
                        <Menu.Item key="1">Activity</Menu.Item>
                        <Menu.Item key="2">Discover</Menu.Item>
                        <Menu.Item key="3">Profile</Menu.Item>
                        <Menu.Item key="4">Jobs</Menu.Item>
                    </Menu>
                </div> */}
            </div>
            <div className="header__right">
                <div className="profile-container">
                    <div><UserOutlined /></div>

                    <div className="profile-details">
                        <label>{user.first_name}</label>
                        <span>Administrator</span>
                    </div>
                </div>
                {/* <div className="action-icon primary">
                    <SettingOutlined />
                </div> */}
                <div className="action-icon primary">
                    <Tooltip placement="bottomRight" title={'Logout'}>
                        <ExportOutlined onClick={onLogout} />
                    </Tooltip>

                </div>
            </div>
        </div>
    );
}

export default withRouter(HeaderView)