import React, { Component } from 'react';
import { Menu } from 'antd';
import {withRouter } from 'react-router-dom';
import { MenuFoldOutlined,
    MenuUnfoldOutlined,
    BarcodeOutlined,
    UserSwitchOutlined,
    DollarOutlined,
    OrderedListOutlined,
    EnvironmentOutlined,
    BarChartOutlined,
    AppstoreOutlined
    } from '@ant-design/icons';
    

class SideMenuView extends Component<any>
{   
    currentPath = this.props.location.pathname;
    constructor(props:any){
        super(props);

        this.currentPath = this.props.location.pathname;
    }
   
   toggleSidebar = () => {
       this.props.collapseToggle(!this.props.siderCollapsed)
   }

   onToggleMenus = (action:string) => {
        this.currentPath = action;
        this.props.history.push(action);
        
   }

    render() {
        return (
            <div className="sidebar">
                <div className="sideToggle">
                    <div onClick={this.toggleSidebar} className="toggle-icon">
                        {this.props.siderCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined/>}    
                    </div>
                </div>
                <Menu mode="inline" defaultSelectedKeys={[this.currentPath]}>
                    <Menu.Item key="/merchants" onClick={this.onToggleMenus.bind(this, '/merchants')}>
                        <UserSwitchOutlined />
                        <span>Merchants</span>
                    </Menu.Item>
                    <Menu.Item key="/orders" onClick={this.onToggleMenus.bind(this, '/orders')}>
                        <OrderedListOutlined />
                        <span>Orders</span>
                    </Menu.Item>
                    <Menu.Item key="/merchant_payables" onClick={this.onToggleMenus.bind(this, '/merchant_payables')}>
                        <DollarOutlined />
                        <span>Merchant Payables</span>
                    </Menu.Item>

                    <Menu.Item key="/delivery_payables" onClick={this.onToggleMenus.bind(this, '/delivery_payables')}>
                        <DollarOutlined />
                        <span>Delivery Payables</span>
                    </Menu.Item>

                    <Menu.Item key="/locations" onClick={this.onToggleMenus.bind(this, '/locations')}>
                        <EnvironmentOutlined />
                        <span>Locations</span>
                    </Menu.Item>

                    <Menu.Item key="/sales_reports" onClick={this.onToggleMenus.bind(this, '/sales_reports')}>
                        <BarChartOutlined /> 
                        <span>Sales Reports</span>
                    </Menu.Item>

                    <Menu.Item key="/categories" onClick={this.onToggleMenus.bind(this, '/categories')}>
                        <AppstoreOutlined />
                        <span>Categories</span>
                    </Menu.Item>

                    <Menu.Item key="/vouchers" onClick={this.onToggleMenus.bind(this, '/vouchers')}>
                        <BarcodeOutlined />
                        <span>Vouchers</span>
                    </Menu.Item>
                    
                </Menu>
            </div>
        );
    }
}

export default withRouter(SideMenuView)